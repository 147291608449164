import React from 'react';
import "./footer.css";
import link1 from "../../img/Facebook Logo.svg";
import link2 from "../../img/Twitter Logo.svg";
import link3 from "../../img/Instagram Logo.svg";

const Footer = () => {
    const year = new Date().getFullYear();
  return (
    <>
      <footer className='black'>
        <div className="wrapper">
            <div className="content-container">
                <div className="links">
                    <a href="#" className='logo'>Sound<span className='red'>DZign</span></a>
                    <div className="social-icons">
                        <a href="#">
                            <img src={link1} alt="" />
                        </a>
                        <a href="#">
                            <img src={link2} alt="" />
                        </a>
                        <a href="#">
                            <img src={link3} alt="" />
                        </a>
                    </div>
                    <div className="copyright">
                    This website is designed by GTCoding Ⓒ {year}
                    </div>
                </div>
                <div className="links">
                    <h3>
                        Quick Links
                    </h3>
                    <ul>
                        <li><a href="#">Blog</a></li>
                        <li><a href="#">Privacy Policy</a></li>
                    </ul>
                </div>
                <div className="links">
                    <h3>Contact Us</h3>
                    <ul>
                        <li><a href="#">contact@email.com</a></li>
                        <li><a href="#">+1 999 9999 999</a></li>
                    </ul>
                </div>
                <div className="copyright-mobile">
                    This website is designed by GTCoding Ⓒ {year}
                    </div>
            </div>
        </div>
      </footer>
    </>
  )
}

export default Footer;
