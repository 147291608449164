import React, { useState } from 'react';
import "./navbar.css";
import menuicon from "../../img/Menu Icon.svg";
import closeicon from "../../img/Frame 4.svg";

const Navbar = () => {
  const [isActive, setIsActive] = useState(false);
  return (
    <>
      <nav>
        <a href="#header" className='logo'>Sound<span className='red'>DZign</span></a>
        <ul>
          <li><a href="#info">About</a></li>
          <li><a href="#topics">Course Details</a></li>
          <li><a href="#blog">Blog</a></li>
          <li><a href="#testimonials">Testimonials</a></li>
        </ul>
        <div onClick= {() => setIsActive(true)} className="menu-icon">
            <img src={menuicon} alt="" />
        </div>
      </nav>
      <div className={`mobile-menu-container ${isActive ? "active" : ""}`}>
          <div className="close-icon" onClick= {() => setIsActive(false)} >
            <img src={closeicon} alt="" />
          </div>
          <ul className='menu-items'>
          <li onClick={() => setIsActive(false)} ><a href="#info">About</a></li>
          <li onClick={() => setIsActive(false)} ><a href="#topics">Course Details</a></li>
          <li onClick={() => setIsActive(false)} ><a href="#blog">Blog</a></li>
          <li onClick={() => setIsActive(false)} ><a href="#testimonials">Testimonials</a></li>
        </ul>
      </div>
    </>
  )
}

export default Navbar;
