import React, { useState } from 'react';
import "./topics.css";
import img1 from "../../img/1.png";
import img2 from "../../img/2.png";
import img3 from "../../img/3.png";
import img4 from "../../img/4.png";
import img5 from "../../img/5.png";
import img6 from "../../img/6.png";
import elem1 from "../../img/Group 7.svg";

const Topics = () => {
    const [currimg, setCurrimg] = useState(img1);
  return (
    <>
      <section id='topics' className='black'>
        <div className="wrapper">
            <h2>What will you learn?</h2>
            <div className="content-container">
                <ul className='topics-lists'>
                    <li onMouseEnter={() => setCurrimg(img1)}>what are frequencies?</li>
                    <li onMouseEnter={() => setCurrimg(img2)}>Using DAW</li>
                    <li onMouseEnter={() => setCurrimg(img3)}>Vocals Processing</li>
                    <li onMouseEnter={() => setCurrimg(img4)}>Mixing</li>
                    <li onMouseEnter={() => setCurrimg(img5)}>Mixing Console</li>
                    <li onMouseEnter={() => setCurrimg(img6)}>Mastering</li>
                </ul>
                <div className="topic-img">
                    <img src={currimg} alt="" />
                </div>
            </div>
            <img src={elem1} alt='elem1' className='element-1'></img>
        </div>
      </section>
    </>
  )
}

export default Topics;
