import React from 'react';
import "./info.css";
import studenticon from "../../img/noto_student-light-skin-tone.svg";
import videoicon from "../../img/flat-color-icons_video-file.svg";
import elem2 from "../../img/Group 7 (1).svg";

const Info = () => {
  return (
    <>
      <section id='info' className='dark-gray'>
            <div className="wrapper">
                <div className="content-container">
                    <div className="info-content">
                        <img src={studenticon} alt="" />
                        <div className="amount">23,000+</div>
                        <div className="type">Students</div>
                    </div>

                    <div className="info-content">
                        <img src={videoicon} alt="" />
                        <div className="amount">26 Hrs</div>
                        <div className="type">Video Content</div>
                    </div>

                </div>
                <img src={elem2} alt="" className='element-2' />
            </div>
      </section>
    </>
  )
};

export default Info;
